export const Empleados = process.env.REACT_APP_COUNTRY === "UY" ? [
    {
        id: 32,
        nombre: "Martin Coral",
        movil: "29000111 int 1301",
        correo: "martin_coral@solutionbox.com.uy",
        cargo: "Gerente General",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/tiny%20para%20vc.jpg",
        category: "gerencia"
    },
    {
        id: 21,
        nombre: "Adolfo Martinez",
        movil: "29000111 Int 1305",
        correo: "adolfo_martinez@solutionbox.com.uy",
        cargo: "Jefe de Product Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Adolfo%20Martinez.jpeg",
        category: "product-manager"
    },
    {
        id: 22,
        nombre: "Adriana Gadea",
        movil: "29000111 Int 1314",
        correo: "adriana_gadea@solutionbox.com.uy",
        cargo: "Jefa de Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Adriana%20Gadea2.jpg",
        category: "ventas"
    },
    {
        id: 15,
        nombre: "Alexis Collazo",
        movil: "29000111 Int 1317",
        correo: "alexis_collazo@solutionbox.com.uy",
        cargo: "Product Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Alexis%20Collazo.jpg",
        category: "product-manager"
    },
    {
        id: 35,
        nombre: "Diego Pereda",
        movil: "29000111 Int 1317",
        correo: "diego_pereda@solutionbox.com.uy",
        cargo: "Product Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Diego%20Pereda.jpg",
        category: "product-manager"
    },
    {
        id: 16,
        nombre: "Gustavo De San Vicente",
        movil: "29000111 Int 1304",
        correo: "gustavo_desanvicente@solutionbox.com.uy",
        cargo: "Product Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Gustavo%20de%20San%20Vicente.jpg",
        category: "product-manager"
    },
    {
        id: 17,
        nombre: "Veronica Oliver",
        movil: "29000111 Int 1315",
        correo: "veronica_oliver@solutionbox.com.uy",
        cargo: "Product Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Veronica%20Oliver.jpg",
        category: "product-manager"
    },
    {
        id: 18,
        nombre: "Marcela Di Paola",
        movil: "29000111 Int 1307",
        correo: "marcela_dipaola@solutionbox.com.uy",
        cargo: "Product Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "product-manager"
    },
    {
        id: 23,
        nombre: "Agustín Frenay",
        movil: "29000111 Int 1306",
        correo: "agustin_frenay@solutionbox.com.uy",
        cargo: "Ejecutivo de Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Agustin%20Frenay.jpg",
        category: "ventas"
    },
    {
        id: 24,
        nombre: "Santiago Oliver",
        movil: "29000111 int 1316",
        correo: "santiago_oliver@solutionbox.com.uy",
        cargo: "Ejecutivo de Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Santiago%20Oliver%20-%20copia.jpg",
        category: "ventas"
    },
    {
        id: 25,
        nombre: "Santiago Vidal",
        movil: "29000111 int 1318",
        correo: "santiago_vidal@solutionbox.com.uy",
        cargo: "Product Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Santiago%20Vidal.jpg",
        category: "product-manager"
    },
    {
        id: 29,
        nombre: "Marcos Alvez",
        movil: "29000111 int 1312",
        correo: "marcos_alvez@solutionbox.com.uy",
        cargo: "Ejecutivo de Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "ventas"
    },
    {
        id: 27,
        nombre: "Debora Caceres",
        movil: "29000111 int 1319",
        correo: "debora_caceres@solutionbox.com.uy",
        cargo: "Ejecutivo de Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Debora%20Caceres.jpg",
        category: "ventas"
    },
    {
        id: 28,
        nombre: "Tatiana Camargo",
        movil: "29000111 int 1302",
        correo: "tatiana_camargo@solutionbox.com.uy",
        cargo: "Administracion",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Uruguay-staff/resized/Tatiana%20Camargo.jpg",
        category: "administracion"
    },
    {
        id: 30,
        nombre: "Ignacio Cespedes",
        movil: "29000111 int 1311",
        correo: "paulo_cespedes@solutionbox.com.uy",
        cargo: "Deposito",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "deposito"
    },
    {
        id: 31,
        nombre: "Marco Martinez",
        movil: "29000111 int 1311",
        correo: "marco_martinez@solutionbox.com.uy",
        cargo: "Deposito",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "deposito"
    },
] : process.env.REACT_APP_COUNTRY === "GT" ? [
    {
        id: 3,
        nombre: "Lester Muñoz",
        movil: "Tel: +502 4768-5977",
        correo: "lester_munoz@solutionbox.com.gt",
        cargo: "Country Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/LESTER%20MU%C3%91OZ%20GUATE.jpg",
        category: "gerencia"
    },
    {
        id: 99,
        nombre: "Jose Medrano",
        movil: "Tel: +503 7728 7502",
        correo: "jose_medrano@solutionbox.com.sv",
        cargo: "PM Hpe-Aruba",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/elsalvador-staff/newStaff/Jose%20Medrano%20(2).jpeg",
        category: 'product-manager'
    },
    {
        id: 4,
        nombre: "Erick Gudiel",
        movil: "+502 3026 9237",
        correo: "erick_gudiel@solutionbox.com.gt",
        cargo: "PM Lenovo | Apc | Samsung Display",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/ERICK%20GUATEMALA.jpg",
        category: "product-manager"
    },
    {
        id: 44,
        nombre: "Angel Diaz",
        movil: "+502 4218 1184",
        correo: "angel_diaz@solutionbox.com.gt",
        cargo: "PM Jabra y Logitech",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/ANGEL%20DIAZ%20GUATE.jpg",
        category: "product-manager"
    },
    {
        id: 5,
        nombre: "Josue Vasquez",
        movil: "Tel: +502 4231 8984",
        correo: "josue_vasquez@solutionbox.com.gt",
        cargo: "Ventas Corporativas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/JOSUE%20GUATAMALA.jpg",
        category: "ventas"
    },
    {
        id: 51,
        nombre: "Daniel Castro",
        movil: "Tel: +502 5448 6841",
        correo: "daniel_castro@solutionbox.com.gt",
        cargo: "Telemarketing",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/DANIEL%20GUATEMALA.jpg", 
        category: "ventas"
    },
    {
        id: 55,
        nombre: "Jose Vasquez",
        movil: "Tel: +502 3582 5299",
        correo: "jose_vasquez@solutionbox.com.gt",
        cargo: "PM Networking y Telefonia",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/JOSE%20VAZQUEZ%20GUATEMALA.jpg",
        category: "product-manager"
    },
    {
        id: 6,
        nombre: "Eddy Morales",
        movil: "+502 4739 7100",
        correo: "eddy_morales@solutionbox.com.gt",
        cargo: "PM OEM",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Staff%20resized/Eddy-morales.png",
        category: "product-manager"
    },
    {
        id: 7,
        nombre: "Kevin Garcia",
        movil: "+502 3760 6000",
        correo: "kevin_garcia@solutionbox.com.gt",
        cargo: "PM de Huawei",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/KEVIN%20GARCIA%20GUATEMALA.jpg",
        category: "product-manager"
    },
    {
        id: 77,
        nombre: "Pablo De Leon",
        movil: "+502 3760 6000",
        correo: "pablo_deleon@solutionbox.com.gt",
        cargo: "PM Targus | Brother",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/PABLO%20DE%20LEON%20GUATEMALA.jpg",
        category: "product-manager"
    },
    {
        id: 8,
        nombre: "Wendy Marroquin",
        movil: "+502 4183 2814",
        correo: "Wendy_marroquin@solutionbox.com.gt",
        cargo: "Insales",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/WENDY%20GUATEMALA.jpg",
        category: "ventas"
    },
    {
        id: 10,
        nombre: "Luis Bonilla",
        movil: "+502 3566 2711",
        correo: "luis_bonilla@solutionbox.com.gt",
        cargo: "Vendedor Departamental",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/LUIS%20GUATEMALA.jpg",
        category: "ventas"
    },
    {
        id: 100,
        nombre: "Keni Gudiel",
        movil: "Tel: +502 5353 2977",
        correo: "keni_gudiel@solutionbox.com.gt",
        cargo: "Ventas Corpoativas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/KENI%20GUDIEL%20GUATE.jpg",
        category: "ventas"
    },
    {
        id: 12,
        nombre: "Dailyn Avila",
        movil: "Tel: +502 5428 8174",
        correo: "dailyn_avila@solutionbox.com.gt",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/DAILYN%20GUATEMALA.jpg",
        category: "ventas"
    },
    {
        id: 122,
        nombre: "Maria Fernanda Castro",
        movil: "Tel: +502 4713 5523",
        correo: "fernanda_castro@solutionbox.com.gt",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffGuatemala/FERNANDA%20GUATEMALA.jpg",
        category: "ventas"
    }
] : process.env.REACT_APP_COUNTRY === "PA" ? [
    {
        id: 1,
        nombre: "Hernando Cordoba",
        movil: "Tel: 202-6400 Ext. 4001",
        correo: "hernando_cordoba@solutionbox.com.pa",
        cargo: "Country Manager",
        img: "https://github.com/RamiroSB/imagenes/blob/main/Panama-Staff/HernandoCordoba.jpeg?raw=true",
        category: "gerencia"
    },
    {
        id: 4,
        nombre: "Edgard Marmolejo",
        movil: "Tel: 202-6400 Ext. 4005",
        correo: "edgard_marmolejo@solutionbox.com.pa",
        cargo: "PM SAMSUNG, LENOVO y APC",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Panama-Staff/Edward.jpg",
        category: "product-manager"
    },
    {
        id: 5,
        nombre: "Doriam Espinosa",
        movil: "Tel: 202-6400 Ext. 4007",
        correo: "doriam_espinosa@solutionbox.com.pa",
        cargo: "PM Huawei",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/stfhn/Doriam%20Espinosa.jpg",
        category: "product-manager"
    },
    {
        id: 15,
        nombre: "Antonio Sandoval",
        movil: "Tel: 202-6400 Ext. 4006",
        correo: "antonio_sandoval@solutionbox.com.pa",
        cargo: "PM Redes, Telefonía y Seguridad",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/stfhn/Antonio%20Sandoval.jpg",
        category: "product-manager"
    },
    {
        id: 7,
        nombre: "Anyuri Cantillo",
        movil: "Tel: 202-6400 Ext. 4003",
        correo: "anyuri_cantillo@solutionbox.com.pa",
        cargo: "PM OEM Y RETAIL",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Panama-Staff/Anyuri%20Cantillo.jpg",
        category: "product-manager"
    },
    {
        id: 77,
        nombre: "Christian Mendez",
        movil: "Tel: +507 6548-2877",
        correo: "Christian_endez@solutionbox.com.pa",
        cargo: "PM Dahua & Panduit",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "product-manager"
    },
    {
        id: 7,
        nombre: "Andrés Francis",
        movil: "Tel: 202-6400 Ext. 4008",
        correo: "andres_francis@solutionbox.com.pa",
        cargo: "Ejecutivo de Cuenta",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Panama-Staff/Andres%20Francis.jpg",
        category: "ventas"
    },
    {
        id: 8,
        nombre: "Katherine Córdoba",
        movil: "Tel: 202-6400 Ext. 4004",
        correo: "katherine_cordoba@solutionbox.com.pa",
        cargo: "Ejecutiva de cuenta",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Panama-Staff/Imagen%20de%20WhatsApp%202023-09-21%20a%20las%2013.04.12.jpg",
        category: "ventas"
    },
    {
        id: 8,
        nombre: "César Alonso",
        movil: "Tel: 202-6400 Ext. 4009",
        correo: "cesar_alonso@solutionbox.com.pa",
        cargo: "Ejecutivo de cuenta",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Panama-Staff/Imagen%20de%20WhatsApp%202023-09-21%20a%20las%2013.03.10.jpg",
        category: "ventas"
    },
    {
        id: 8,
        nombre: "Yarleris Chanis",
        movil: "Tel: 202-6400 Ext. 4010",
        correo: "yarleris_chanis@solutionbox.com.pa",
        cargo: "Ejecutiva de Telemarketing",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Panama-Staff/Yarleris.jpg",
        category: "ventas"
    }
] : process.env.REACT_APP_COUNTRY === "HN" ? [
    {
        id: 1,
        nombre: "Karla Orellana",
        correo: "karla_orellana@solutionbox.com.hn",
        movil: "Tel: 504 3376-8585",
        cargo: "Country Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/NUEVOSTAFF/KARLA%20ORELLANA.jpg",
        category: "country-manager"
    },
    {
        id: 11,
        nombre: "Karla Chavez",
        correo: "karla_chavez@solutionbox.com.hn",
        movil: "Tel: 504 9754-3850",
        cargo: "PM OEM/RETAIL",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/NUEVOSTAFF/KARLA%20CHAVEZ%20HN.jpg", 
        category: "product-manager"
    },
    {
        id: 333,
        nombre: "Bessy Zapata",
        movil: "Tel: 504 8890-9911",
        correo: "bessy_zapata@solutionbox.com.hn",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/NUEVOSTAFF/BESSY%20ZAPATA.jpg", 
        category: "ventas"
    },
    {
        id: 332,
        nombre: "Merary Elizabeth Matute Iscano",
        movil: "Tel: +504 3340-0234",
        correo: "elizabeth_matute@solutionbox.com.hn",
        cargo: "Telemarketing",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/refs/heads/main/HondurasResized/NUEVOSTAFF/Merary%20Elizabeth%20Matute%20Iscano%20HN.jpg", 
        category: "ventas"
    },
    {
        id: 4,
        nombre: "Ashly Ochoa",
        movil: "Tel: 504 94752432",
        correo: "ashly_ochoa@solutionbox.com.hn",
        cargo: "Encargado administrativo y Bodega",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/stfhn/ASHLY%20OCHOA%20ADMINISTRACION.JPG",
        category: "administracion"
    },
    {
        id: 5,
        nombre: "Avy Meza",
        movil: "Tel: 504 9937 3575",
        correo: "avy_meza@solutionbox.com.hn",
        cargo: "PM LENOVO - APC",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/stfhn/AVY%20MEZA%20PM%20LENOVO%20APC.JPG",
        category: "product-manager"
    },
    {
        id: 6,
        nombre: "Claudia Sabillon",
        movil: "Tel: 504 2247-3320 Ext.4211",
        correo: "claudia_sabillon@solutionbox.com.hn",
        cargo: "PM HUAWEI - JABRA",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/stfhn/CLAUDIA%20SABILLON%20PM%20HUAWEI%20JABRA.JPG",
        category: "product-manager"
    },
    {
        id: 7,
        nombre: "Juan Martinez",
        movil: "Tel: (504) 9716-3247",
        correo: "juan_martinez@solutionboxusa.com ",
        cargo: "PM REGIONAL HUAWEI CLOUD",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/stfhn/JUAN%20MARTINEZ%20COUNTRY%20MANAGER.JPG",
        category: "product-manager"
    },
    {
        id: 11,
        nombre: "Oscar Diaz",
        movil: "Tel: (504) 9779-3022",
        correo: "oscar_diaz@solutionbox.com.hn",
        cargo: "PM Networking/Telefonia",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/stfhn/OSCAR%20DIAZ%20PM%20NETWORKING%20Y%20TELEFONIA%20(1).JPG",
        category: "product-manager"
    },
    {
        id: 9,
        nombre: "Kellen Villeda",
        movil: "Tel: 504 9626-1178",
        correo: "kellen_villeda@solutionbox.com.hn",
        cargo: "Ventas SPS",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/stfhn/KELLEN%20VILLEDA%20VENTAS%20SPS2.jpg",
        category: "ventas"
    },
    {
        id: 10,
        nombre: "Maria Bonillla",
        movil: "Tel: (504) 2247-3320 ext 3908",
        correo: "maria_bonilla@solutionbox.com.hn",
        cargo: "Ventas Corporativas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/NUEVOSTAFF/MARIA%20BONILLA.jpg",
        category: "ventas"
    },
    {
        id: 12,
        nombre: "Edwin Soto",
        movil: "Tel: (504) 9786-1843",
        correo: "edwin_soto@solutionbox.com.hn",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/HondurasResized/stfhn/EDWIN%20SOTO.jpg",
        category: "ventas"
    },
    {
        id: 122,
        nombre: "Dunia Godoy",
        movil: "Tel: (504) 92247-3320 Ext. 4202",
        correo: "dunia_godoy@solutionbox.com.hn",
        cargo: "PM HPInc",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/refs/heads/main/HondurasResized/NUEVOSTAFF/DUNIA%20GODOY%20HN.jpg",
        category: "product-manager"
    }

] : process.env.REACT_APP_COUNTRY === "SV" ? [
    {
        id: 1,
        nombre: "Margarita Carcamo",
        movil: "Tel: (503) 7844-7998",
        correo: "margarita_carcamo@solutionbox.com.sv",
        cargo: "Country Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/elsalvador-staff/newStaff/Margarita%20Carcamo.jpeg",
        category: 'country-manager'
    },
    {
        id: 2,
        nombre: "Jorge Lam",
        movil: "Tel: (503) 6988 9625",
        correo: "jorge_lam@solutionbox.com.sv",
        cargo: "PM Lenovo/APC/SAMSUNG SIGNAGE",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/elsalvador-staff/newStaff/Jorge%20Lam.jpeg",
        category: 'product-manager'

    },
    {
        id: 3,
        nombre: "Moises Hidalgo",
        movil: "Tel: (503) 7435-6151",
        correo: "moises_hidalgo@solutionbox.com.sv",
        cargo: "PM OEM/Apple/GoPro",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/elsalvador-staff/newStaff/Moises%20Hidalgo.jpeg",
        category: 'product-manager'
    },
    {
        id: 4,
        nombre: "Jose Medrano",
        movil: "Tel: (503) 7728 7502",
        correo: "jose_medrano@solutionbox.com.sv",
        cargo: "PM HPe Aruba Networks",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/elsalvador-staff/newStaff/Jose%20Medrano%20(2).jpeg",
        category: 'product-manager'
    },
    {
        id: 5,
        nombre: "Cecilia Lemus",
        movil: "Tel: (503) 7900 0479",
        correo: "cecilia_lemus@solutionbox.com.sv",
        cargo: "PM Huawei",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: 'product-manager'
    },
    {
        id: 6,
        nombre: "Karla Ramirez",
        movil: "Tel: 503 7470 1193 ",
        correo: "karla_ramirez@solutionbox.com.sv",
        cargo: "Telemarketing",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png", 
        category: 'ventas'
    },
    {
        id: 8,
        nombre: "Katya Villeda",
        movil: "Tel: 2133-9110 (EXT 4610)",
        correo: "katya_villeda@solutionbox.com.sv",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: 'ventas'
    },
    {
        id: 9,
        nombre: "Flor Peraza",
        movil: "Tel: +503 7712 1038",
        correo: "Flor_peraza@solutionbox.com.sv",
        cargo: "PM Jabra / Logitech",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: 'product-manager'
    },
    {
        id: 10,
        nombre: "Lucy Ramirez",
        movil: "Tel: 2133-9110 (EXT 4603)",
        correo: "luz_ramirez@solutionbox.com.sv",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: 'ventas'
    },
    {
        id: 11,
        nombre: "Luis Ramirez",
        movil: "Tel: '2133-9100 (ext. 4604)",
        correo: "luis_ramirez@solutionbox.com.sv",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: 'ventas'
    },
    {
        id: 112,
        nombre: "Alexander Chavarria",
        movil: "Tel: '2133-9100 (ext. 4923)",
        correo: "alexander_chavarria@solutionbox.com.sv",
        cargo: "PM Regional de NSX y Targus",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/refs/heads/main/elsalvador-staff/newStaff/ALEXANDER%20CHAVARRIA%20SV.jpg",
        category: 'product-manager'
    }
] : process.env.REACT_APP_COUNTRY === "PY" ? [
    {
        id: 1,
        nombre: "Alfredo Colman",
        movil: "",
        correo: "alfredo_colman@solutionbox.com.py",
        cargo: "Country Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Paraguay-staff/new-staff/Alfredo%20Colman.JPG",
        category: "country-manager"
    },
    {
        id: 2,
        nombre: "Martin Ovando",
        movil: "",
        correo: "martin_ovando@solutionbox.com.py",
        cargo: "PM TELEFONIA Y SEGURIDAD",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Paraguay-staff/new-staff/Martin%20Ovando.JPG",
        category: "product-manager"
    },
    {
        id: 4,
        nombre: "Ruben Morinigo",
        movil: "",
        correo: "ruben_morinigo@solutionbox.com.py",
        cargo: "PM de Huawei Enterprise, PM de Xfuncion",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Paraguay-staff/new-staff/Ruben%20Morinigo.JPG",
        category: "product-manager"
    },
    {
        id: 6,
        nombre: "Liz Gonzalez",
        movil: "",
        correo: "liz_gonzalez@solutionbox.com.py",
        cargo: "Administrativa",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Paraguay-staff/new-staff/Liz%20Gonzalez.JPG",
        category: "administracion"
    },
    {
        id: 7,
        nombre: "Susana Mareco",
        movil: "",
        correo: "susana_marecos@solutionbox.com.py",
        cargo: "PM de Jabra, PM de Zebra, PM de Targus (accesorios)",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Paraguay-staff/new-staff/Susana%20Mareco.jpg",
        category: "product-manager"
    },
    {
        id: 8,
        nombre: "Edgar Meza",
        movil: "",
        correo: "edgar_meza@solutionbox.com.py",
        cargo: "ENCARGADO DE LOGISTICA",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Paraguay-staff/new-staff/Edgar%20Meza.JPG",
        category: "gerencia"
    },
    {
        id: 9,
        nombre: "Thiara Mendez",
        movil: "",
        correo: "thiara_mendez@solutionbox.com.py",
        cargo: "Telemarketer",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Paraguay-staff/new-staff/Thiara%20Mendez.JPG",
        category: "ventas"
    },
    {
        id: 10,
        nombre: "Alicia Galarza",
        movil: "",
        correo: "alicia_galarza@solutionbox.com.py",
        cargo: "PM/ Comercial Huawei Enterprise (switch, Pantallas interactivas y Ap)",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Paraguay-staff/new-staff/Alicia%20Galarza.jpg",
        category: "product-manager"
    },
    {
        id: 12,
        nombre: "Miguel Sanchez",
        movil: "",
        correo: "miguel_sanchez@solutionbox.com.py",
        cargo: "PM de Lenovo (notebooks, tablets), PM de Corsair (sillas gamer)",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Paraguay-staff/new-staff/Miguel%20Sanchez.JPG",
        category: "product-manager"
    }
] : process.env.REACT_APP_COUNTRY === "DO" ? [
    {
        id: 1,
        nombre: "Felix Guerrero",
        movil: "Tel: 809-531-2200 Ext 3601",
        cargo: "Country Manager",
        correo: "felix_guerrero@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-domincana/Felix%20Guerrero.jpg",
        category: "country-manager"
    },
    {
        id: 2,
        nombre: "Rosalba Frias",
        movil: "Tel: 809-531-2200 Ext 3620",
        cargo: "Gerente Administrativa",
        correo: "rosalba_frias@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-domincana/Rosalba%20Frias.jpg",
        category: "administracion"
    },
    {
        id: 4,
        nombre: "Ronnie Camilo Garcia",
        movil: "Tel: 809-531-2200 Ext 3605",
        cargo: "PM Huawei",
        correo: "ronnie_camilo@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-domincana/Ronnie%20Camilo.jpg",
        category: "product-manager"
    },
    {
        id: 7,
        nombre: "Patricia Baez",
        movil: "Tel: 809-531-2200 Ext 3604",
        cargo: "PM Regional RD-PR-Caribe ENG/Jabra-Lenovo",
        correo: "patricia_baez@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-domincana/Patricia%20Baez.jpg",
        category: "product-manager"
    },
    {
        id: 77,
        nombre: "Aneury Valdez Belen",
        movil: "Tel: 809-531-2200 Ext 3606",
        cargo: "PM Seguridad/Energía/Cableado ",
        correo: "aneury_valdez@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "product-manager"
    },
    {
        id: 10,
        nombre: "Guillermo Lantigua ",
        movil: "Tel: 809-531-2200 Ext 3607",
        cargo: "PM OEM",
        correo: "guillermo_lantigua@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-domincana/Guillermo%20Lantigua.jpg",
        category: "product-manager"
    },
    {
        id: 11,
        nombre: "Pablo Hernandez",
        movil: "Tel: 809-531-2200 ext 3609",
        cargo: "PM Wireless & Telefonica",
        correo: "pablo_hernandez@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "product-manager"
    },
    {
        id: 12,
        nombre: "Bianna Paredes",
        movil: "Tel: 809-531-2200 Ext 3613",
        cargo: "PM Security, energy & wiring Caribe",
        correo: "bianna_paredes@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-domincana/Bianna%20Paredes.jpg",
        category: "product-manager"
    },
    {
        id: 122,
        nombre: "César Alcántara",
        movil: "Tel: 809-531-2200 Ext 3611",
        cargo: "PM HPC & HPL",
        correo: "cesar_alcantara@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "product-manager"
    },
    {
        id: 13,
        nombre: "Yohanna Acevedo",
        movil: "Tel: 809-531-2200 Ext 3602",
        cargo: "Representante de ventas",
        correo: "yohanna-acevedo@solutionbox.com",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-domincana/Yohanna%20Acevedo.jpg",
        category: "ventas"
    },
    {
        id: 133,
        nombre: "Orquidea Ramirez",
        movil: "Tel: 809-531-2200 Ext 3608",
        cargo: "Representante de ventas",
        correo: "orquidea_ramirez@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "ventas"
    },
    {
        id: 14,
        nombre: "Melissa Castillo",
        movil: "Tel: 809-531-2200 Ext 3608",
        cargo: "PM HPE & Aruba",
        correo: "melissa_castillo@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-domincana/Melissa%20Castillo.jpg",
        category: "product-manager"
    },
    {
        id: 16,
        nombre: "Diana Rodriguez",
        movil: "Tel: 809-531-2200 Ext 3630",
        cargo: "Representante de ventas",
        correo: "diana_rodriguez@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-domincana/Diana%20Rodriguez.jpg",
        category: "ventas"
    }
    ,
    {
        id: 17,
        nombre: "Yunisa Bonilla",
        movil: "Tel: 809-531-2200 Ext 3615",
        cargo: "Representante de ventas",
        correo: "yunisa_bonilla@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png", 
        category: "ventas"
    },
    {
        id: 18,
        nombre: "Jerrinson Pichardo",
        movil: "Tel: 809-531-2200 Ext 3615",
        cargo: "Representante de ventas Santiago",
        correo: "jerrinson_pichardo@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "ventas"
    },
    {
        id: 19,
        nombre: "Dahianna Silverioo",
        movil: "Tel: 809-531-2200 Ext 3630",
        cargo: "Telemarketing",
        correo: "dahianna_silverio@solutionbox.com.do",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png", 
        category: "ventas"
    }
] : process.env.REACT_APP_COUNTRY === "USA" ? [
    {
        id: 2,
        nombre: "Pablo Guatelli",
        movil: "+1-305-722-3825 ext 3530",
        correo: "pablo_guatelli@solutionboxusa.com",
        cargo: "Commercial Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Staff%20resized/PabloGuatelli1.jpg",
        category: "manager"
    },
    {
        id: 3,
        nombre: "Maximiliano Franco",
        movil: "+1-305-722-3825 ext 1288",
        correo: "maximiliano_franco@solutionboxusa.com",
        cargo: "Regional Sales Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/LLC-Staff/Maximiliano.png",
        category: "manager"
    },
    {
        id: 4,
        nombre: "Araceli Lencina",
        movil: "+1-305-722-3825 ext 1521",
        correo: "araceli_lencina@solutionboxusa.com",
        cargo: "Account Executive",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/LLC-Staff/Araceli.png",
        category: "sales"
    },
    {
        id: 5,
        nombre: "Enrique Rivas",
        movil: "+1-305-722-3825 ext 1524",
        correo: "enrique_rivas@solutionboxusa.com",
        cargo: "Account Executive",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/LLC-Staff/Enrique.png",
        category: "sales"
    },
    {
        id: 7,
        nombre: "Tirso Nunez",
        movil: "+1-305-722-3825 ext 1285",
        correo: "tirso_nunez@solutionboxusa.com",
        cargo: "Account Executive",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/LLC-Staff/Tirso.png",
        category: "sales"
    },
    {
        id: 8,
        nombre: "Jenissel P. Rodriguez",
        movil: "+1-305-722-3825 ext 3531",
        correo: "jenissel_rodriguez@solutionboxusa.com",
        cargo: "Account Executive",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "sales"
    },
    {
        id: 9,
        nombre: "Juniaksi Nuñez",
        movil: "+1-305-722-3825 ext 3511",
        correo: "juniaksi_nunez@solutionboxusa.com",
        cargo: "Account Executive",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "sales"
    },
    {
        id: 10,
        nombre: "Martina Juan",
        movil: "+1-305-722-3825 ext 1566",
        correo: "martina_juan@solutionboxusa.com",
        cargo: "Account Executive",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "sales"
    },
    {
        id: 11,
        nombre: "Juan Solis",
        movil: "+1-305-722-3825 ext 1564",
        correo: "juan_solis@solutionboxusa.com",
        cargo: "Account Executive",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "sales"
    },
    {
        id: 12,
        nombre: "Adrian Romero",
        movil: "+1-305-722-3825",
        correo: "adrian_romero@solutionboxusa.com",
        cargo: "Team Leader OEM",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "product-manager"
    },
    {
        id: 13,
        nombre: "Sergio Colonna",
        movil: "+ 54 (011) 6091-1200",
        correo: "sergio_colonna@solutionbox.com.ar",
        cargo: "PM HPE Server & Storage",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "product-manager"
    },
    {
        id: 14,
        nombre: "Juan Wolozsyn",
        movil: "+ 54 (011) 6091-1200",
        correo: "juan_wolozsyn@solutionbox.com.ar",
        cargo: "PM Aruba",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "product-manager"
    },
    {
        id: 15,
        nombre: "Alejandra Murillo",
        movil: "+1-305-722-3825",
        correo: "alejandra_murillo@solutionboxusa.com",
        cargo: "PM IP Telefonía",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "product-manager"
    },
    {
        id: 16,
        nombre: "Natalia Sutter",
        movil: "+1-305-722-3825",
        correo: "anatalia_sutter@solutionboxusa.com",
        cargo: "Team Leader Lenovo e Infraestructura",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "product-manager"
    },
    {
        id: 17,
        nombre: "Luis Martinho",
        movil: "+1-305-722-3825",
        correo: "luis_martinho@solutionboxusa.com",
        cargo: "Team leader Networking",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "product-manager"
    },
    {
        id: 18,
        nombre: "Yesenia Valverde",
        movil: "506-2106-5610 ext 3706",
        correo: "luis_martinho@solutionboxusa.com",
        cargo: "Team Leader Jabra / Logitech ",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "product-manager"
    },
    {
        id: 19,
        nombre: "Valentina Marrapodi",
        movil: "305-777-17-59 ext 1522",
        correo: "valentina_marrapodi@solutionbosusa.com",
        cargo: "PM Cybersecurity / IoT / Storage & Servers",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "product-manager"
    }

] : process.env.REACT_APP_COUNTRY === "CR" ? [
    {
        id: 2,
        nombre: "Andrey de la O",
        movil: "Tel: 506 2106 5610 Ext. 3703",
        correo: "andrey_delao@solutionbox.cr",
        cargo: "Encargado administrativo y Bodega",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/ANDREY.jpg",
        category: "administracion"
    },
    {
        id: 3,
        nombre: "Luis Cruz",
        movil: "Tel: 506 2106 5610 Ext. 3721",
        correo: "Luis_cruz@solutionbox.cr",
        cargo: "Asistente de Bodega",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/LUIS.jpg",
        category: "deposito"
    },
    {
        id: 4,
        nombre: "David Jimenez",
        movil: "Tel: 506 2106 5610 Ext. 3707",
        correo: "David_jimenez@solutionbox.cr",
        cargo: "PM HPE / Aruba / APC",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/DAVID.jpg",
        category: "product-manager"
    },
    {
        id: 9,
        nombre: "Alejandro Garcia",
        movil: "Tel: 506 2106 5610 Ext. 3713",
        correo: "Alejandro_garcia@solutionbox.cr",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/ALEJANDRO.jpg",
        category: "ventas"
    },
    {
        id: 10,
        nombre: "Susan Gómez",
        movil: "Tel: 506 2106 5610 Ext. 3717",
        correo: "Susan_gomez@solutionbox.cr",
        cargo: "PM Jabra",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/SUSAN.jpg",
        category: "product-manager"
    },
    {
        id: 12,
        nombre: "Andres Padilla",
        movil: "Tel: 506 2106 5610 Ext. 3716",
        correo: "Andres_padilla@solutionbox.cr",
        cargo: "PM Networking / Telefonía",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/ANDRES.jpg",
        category: "product-manager"
    },
    {
        id: 12,
        nombre: "Adriana Guerrero",
        movil: "Tel: 506 2106 5610 Ext. 3720",
        correo: "Adriana_guerrero@solutionbox.cr",
        cargo: "Team Leader HPI-SIGNAGE-KODAK",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/ADRIANA%20GUERRERO%20CR%20.png",
        category: "product-manager"
    },
    {
        id: 11,
        nombre: "Yesenia Valverde",
        movil: "Tel: 506 2106 5610 Ext. 3706",
        correo: "yesenia_valverde@solutionbox.cr",
        cargo: "Team leader Jabra",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/YESENIA.jpg",
        category: "product-manager"
    },
    {
        id: 14,
        nombre: "Silvia Zuñiga",
        movil: "Tel: 506 2106 5610 Ext. 3701",
        correo: "Silvia_zuniga@solutionbox.cr",
        cargo: "Country Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/SILVITA.jpg",
        category: "country-manager"
    },
    {
        id: 15,
        nombre: "Natalia Morales",
        movil: "Tel: 506 2106 5610 Ext. 3709",
        correo: "Natalia_morales@solutionbox.cr",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/NATALIA.jpg",
        category: "ventas"
    },
    {
        id: 155,
        nombre: "Katherine Flores",
        movil: "Tel: 506 2106 5610 Ext. 3712",
        correo: "Katherine_flores@solutionbox.cr",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoFemenino.png",
        category: "ventas"
    },
    {
        id: 17,
        nombre: "Luisa Herrera",
        movil: "Tel: 506 2106 5610 Ext. 3705",
        correo: "Luisa_herrera@solutionbox.cr",
        cargo: "Ventas",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/LUISA.jpg",
        category: "ventas"
    },
    {
        id: 177,
        nombre: "Alfredo Quesada",
        movil: "Tel: 506 7007 7538",
        correo: "alfredo_quesada@solutionbox.cr",
        cargo: "PM Huawei",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/ALFREDO%20QUESADA%20CR%20.jpg",
        category: "product-manager"
    },
    {
        id: 19,
        nombre: "Georgina Araya",
        movil: "Tel: 506 2106 5610 Ext. 3718",
        correo: "Georgina_araya@solutionbox.cr",
        cargo: "Telemarketer",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/StaffCR/GEORGINA.jpg",
        category: "ventas"
    },
    {
        id: 179,
        nombre: "Jorge Suarez Chaves",
        movil: "Tel: 506 8704 9541",
        correo: "jorge_suarez@solutionbox.cr",
        cargo: "PM Lenovo y Samsung Signage",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/refs/heads/main/StaffCR/JORGE%20SUAREZ%20CHAVES%20CR%20.jpg",
        category: "product-manager"
    },
    {
        id: 175,
        nombre: "Neithan Arias",
        movil: "Tel: 506 7033 2322",
        correo: "neithan_arias@solutionbox.cr",
        cargo: "PM Oem + Consumo",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/refs/heads/main/StaffCR/NEITHAN%20ARIAS%20CR%20.jpg",
        category: "product-manager"
    },
] 
: process.env.REACT_APP_COUNTRY === "CL" ? [
    {
        id: 1,
        nombre: "Luis Acevedo",
        movil: "+56 9 4780 0852",
        correo: "luis_acevedo@solutionbox.cl",
        cargo: "Product Manager Huawei",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-chile/Luis_Acevedo_pm_huawei_aruba_y_Lenovo.webp",
        category: "product-manager"
    },
    {
        id: 3,
        nombre: "Jorge Carrasco",
        movil: "+56 9 9128 2221",
        correo: "jorge_carrasco@solutionbox.cl",
        cargo: "Gerente General",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-chile/Jorge_Carrasco.webp",
        category: "gerencia"
    },
    {
        id: 2,
        nombre: "Rocio Ruiz",
        movil: "+56956411855",
        correo: "rocio_ruiz@solutionbox.cl",
        cargo: "Product Manager HPE",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-chile/RocioRuiz.png",
        category: "product-manager"
    },
    {
        id: 4,
        nombre: "Catalina Molins",
        movil: "+56 9 4155 3838",
        correo: "catalina_molins@solutionbox.cl",
        cargo: "Product Manager APC",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-chile/Catalina_Molins_PM_APC.webp",
        category: "product-manager"
    },
    {
        id: 6,
        nombre: "Jose Oliva",
        movil: "+56 9 33721045",
        correo: "jose_oliva@solutionbox.cl",
        cargo: "Product Manager Lenovo",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "product-manager"
    },
    {
        id: 7,
        nombre: "Melisa López",
        movil: "+56 9 3026 8771",
        correo: "melisa_lopez@solutionbox.cl",
        cargo: "Ejecutivo Comercial",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-chile/MelisaLopez.png",
        category: "ventas"
    },
    {
        id: 8,
        nombre: "Juan Silva",
        movil: "+56 9 5138 3640",
        correo: "juan_silva@solutionbox.cl",
        cargo: "Ejecutivo Comercial",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/SinFotoMasculino.png",
        category: "ventas"
    },
    {
        id: 9,
        nombre: "Luz Caviedes",
        movil: "+56945186033",
        correo: "luz_caviedes@solutionbox.cl",
        cargo: "Ejecutivo Comercial",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/staff-chile/Luz.png",
        category: "ventas"
    },
] : process.env.REACT_APP_COUNTRY === "PR" ? [
    {
        id: 1,
        nombre: "Claudio Muñoz",
        movil: "+502 4768-5977",
        correo: "claudio_munoz@solutionboxusa.com",
        cargo: "Country Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Staff%20resized/ClaudioMunos.jpeg",
        category: "country-manager"
    },
    {
        id: 2,
        nombre: "Pablo Guatelli",
        movil: "+502 4768-5977",
        correo: "pablo_guatelli@solutionboxusa.com",
        cargo: "Commercial Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Staff%20resized/PabloGuatelli1.jpg",
        category: "manager"
    },
    {
        id: 3,
        nombre: "Maximiliano Franco",
        movil: "+502 4768-5977 int (1288)",
        correo: "max_franco@solutionboxusa.com",
        cargo: "Regional Sales Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/LLC-Staff/Maximiliano.png",
        category: "manager"
    }
] : process.env.REACT_APP_COUNTRY === "TT" ? [
    {
        id: 1,
        nombre: "Claudio Muñoz",
        movil: "+502 4768-5977",
        correo: "claudio_munoz@solutionboxusa.com",
        cargo: "Country Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Staff%20resized/ClaudioMunos.jpeg",
        category: "country-manager"
    },
    {
        id: 2,
        nombre: "Pablo Guatelli",
        movil: "+502 4768-5977",
        correo: "pablo_guatelli@solutionboxusa.com",
        cargo: "Commercial Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Staff%20resized/PabloGuatelli1.jpg",
        category: "manager"
    },
    {
        id: 3,
        nombre: "Maximiliano Franco",
        movil: "+502 4768-5977 int (1288)",
        correo: "max_franco@solutionboxusa.com",
        cargo: "Regional Sales Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/LLC-Staff/Maximiliano.png",
        category: "manager"
    }
] : process.env.REACT_APP_COUNTRY === "JM" ? [
    {
        id: 1,
        nombre: "Claudio Muñoz",
        movil: "+502 4768-5977",
        correo: "claudio_munoz@solutionboxusa.com",
        cargo: "Country Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Staff%20resized/ClaudioMunos.jpeg",
        category: "country-manager"
    },
    {
        id: 2,
        nombre: "Pablo Guatelli",
        movil: "+502 4768-5977",
        correo: "pablo_guatelli@solutionboxusa.com",
        cargo: "Commercial Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/Staff%20resized/PabloGuatelli1.jpg",
        category: "manager"
    },
    {
        id: 3,
        nombre: "Maximiliano Franco",
        movil: "+502 4768-5977 int (1288)",
        correo: "max_franco@solutionboxusa.com",
        cargo: "Regional Sales Manager",
        img: "https://raw.githubusercontent.com/RamiroSB/imagenes/main/LLC-Staff/Maximiliano.png",
        category: "manager"
    }
]
    : ""