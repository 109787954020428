import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'

import { contexto } from '../Context/Context';
import { SwalFire } from '../helpers/SwalFire';
import '../Carrito/Carrito.css';
import { toast } from 'react-toastify';
import { cartContext } from '../Context/CartContext';
import { useTranslation } from 'react-i18next';

const BtnPostAdd = ({ producto }) => {

    const navigate = useNavigate();
    const { addToCart, cartLocal } = useContext(cartContext)
    const itemInCart = cartLocal.length === 0 ? undefined : cartLocal.find(item => item.Alias === producto.Alias);

    const [t, i18next] = useTranslation("global")

    const onAdd = () => {
        if (!itemInCart || itemInCart.length === 0 || producto.Stock - itemInCart.contador - 1 >= 0) {
            addToCart(producto, 1);
            navigate('/cart')
        }
        else {
            //SwalFire('Error!', 'El stock actual es insuficiente para agregar más productos','error')
            toast.error(t("producto.stock-insuficiente"), {
                autoClose: 400
            });
        }
    }

    const onAdd2 = () => {
        navigate('/cart')
    }

    return (
        <div className='btnPostAdd'>
            {/* <Button variant='success' onClick={onAdd} id='btnIrAlCarrito'>
                {t("producto.agregar")}
            </Button> */}
            <button onClick={onAdd} className='btnIrAlCarrito' id='btnIrAlCarrito'>{t("producto.agregar")}</button>
            <br />
            {/* <Button variant='success' onClick={onAdd2} id='btnIrAlCarrito2'>
            {t("producto.ir-carrito")}
            </Button> */}
            <button onClick={onAdd2} id='btnIrAlCarrito2'>{t("producto.ir-carrito")}</button>
        </div>
    )
}

export default BtnPostAdd
